.App {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100vh;
}

.App_header {
    padding: 12px 32px;
}

.App_main {
    overflow: hidden;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: #eaeaea;
    padding: 3px;
    gap: 3px;
}

.App_Bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 3px;
    padding: 12px;
}

.App_BottomBtn {
    cursor: pointer;
    margin: 0 12px;
    padding: 4px 8px;
    border-radius: 3px;
    border: 0 solid transparent;
    background-color: lightgray;
}

.App_Length {
    display: inline-block;
    text-align: center;
    width: 32px;
}

.Table {
    overflow: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.Row {
    position: relative;
    display: flex;
    gap: 3px;
    padding-right: 92px;
}

.Row_summary {
    position: sticky;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 92px;
    min-width: 92px;
    height: 100%;

    font-size: 12px;
    font-weight: bold;
    background-color: #c5c5c5;
    border-radius: 4px;
}

.Cell {
    border: 0 solid transparent;
    border-radius: 4px;
    padding: 12px;
}
